import {
  Box,
  ContentWrapper,
  FlexRow,
  Button,
  ArrowDownIcon,
  NoData,
  RecordIcon,
  InfoIcon,
  IconButton
} from '@papertrail/styleguide'
import React, { useEffect, useState } from 'react'
import { useApiGet } from '@papertrail/web3-utils'
import { useLocation, useHistory } from 'react-router-dom'
import SummaryHeader from './SummaryHeader'
import SummaryFooter from './SummaryFooter'
import NextCheckAt from './NextCheckAt'
import RecordField from './RecordField'
// import OverdueBy from './overdueBy'
import { Popover, Typography } from '@mui/material'
import { Description } from '@mui/icons-material'

const RecordSummary = () => {
  const [recordSummary, loadRecordSummary] = useApiGet(`/public/record`, (data) => data)
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const source = params.get('s')
  const value = params.get('v')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const showPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const goToRecord = (link) => {
    sessionStorage.setItem('redirectUrl', link)
    history.push(link)
  }

  useEffect(() => {
    if (source && value) {
      loadRecordSummary({ s: source, v: value })
    } else {
      history.push('/login')
    }
    sessionStorage.removeItem('redirectUrl')
  }, [])

  return (
    <ContentWrapper>
      <SummaryHeader></SummaryHeader>
      <div style={{ maxHeight: '100vh', overflowY: 'auto', background: '#354a63' }}>
        {recordSummary.error && (
          <Box
            sx={{
              backgroundColor: 'white',
              color: '333333',
              display: 'flex',
              justifyContent: 'center',
              height: '80vh'
            }}>
            <NoData icon={RecordIcon} text="No Record(s) Found" />
          </Box>
        )}
        {recordSummary.isLoaded && recordSummary.data.data && recordSummary.data.data.length > 1 && (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            color="white"
            sx={{ padding: '16px 0px 0px 16px', textDecoration: 'underline', fontSize: '14px' }}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
              {`${recordSummary.data.data.length} Record(s) Found`}

              <ArrowDownIcon />

              <IconButton sx={{ color: 'white' }} onMouseEnter={(e) => showPopover(e)} onClick={(e) => showPopover(e)}>
                <InfoIcon />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}>
                <Typography sx={{ p: 1 }}>Scroll to view all records</Typography>
              </Popover>
            </Box>
          </Box>
        )}

        {recordSummary.isLoaded && recordSummary.data && recordSummary.data.data && recordSummary.data.data.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly'
            }}>
            {recordSummary.data.data.map((item, index) => (
              <div key={index}>
                <Box padding={2} display={'flex'} justifyContent={'center'} alignContent={'center'}>
                  <Box flex={1}>
                    <Box
                      sx={{ backgroundColor: 'white', color: '333333', textAlign: 'center', marginBottom: '8px' }}
                      padding={4}>
                      <Box
                        height="250px"
                        width="277px"
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}>
                        {item.product && item?.product?.images[0] && (
                          <img width="200px" style={{ maxHeight: '250px' }} src={item.product.images[0]}></img>
                        )}
                        {!item.product && (
                          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                            <Description color={'disabled'} sx={{ fontSize: '150px' }} />
                          </Box>
                        )}
                      </Box>

                      <Box marginBottom={'16px'} height={'40px'}>
                        <h3 style={{ maxWidth: '277px' }}>{item?.name}</h3>
                      </Box>
                      <NextCheckAt item={item}></NextCheckAt>
                      <RecordField name={'Name'} value={item?.name}></RecordField>
                      <RecordField name={'Serial'} value={item?.fields?.serial}></RecordField>
                      <RecordField name={'Manufacturer'} value={item?.fields.manufacturer}></RecordField>

                      <Box paddingBottom={'4px'} paddingTop={'4px'}>
                        {' '}
                        <FlexRow justifyContent={'space-between'}>
                          <Box>Last Inspected</Box>
                          <Box maxWidth={'150px'} height={'60px'}>
                            <h4>
                              {item?.log?.logged_at &&
                                new Date(item?.log?.logged_at).toLocaleDateString('en-uk', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric'
                                })}
                            </h4>
                          </Box>
                        </FlexRow>
                      </Box>
                      {/* <OverdueBy loggedAt={item?.log.logged_at}></OverdueBy> */}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ padding: '8px 16px' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Button
                    sx={{
                      width: '100%',
                      color: 'white',
                      background: '#007aff',
                      padding: '8px',
                      maxWidth: '400px',
                      '&:hover': {
                        background: '#007aff'
                      }
                    }}
                    onClick={() => goToRecord(item?.link)}>
                    Manage in Papertrail
                  </Button>
                </Box>
              </div>
            ))}
          </div>
        )}

        <SummaryFooter></SummaryFooter>
      </div>
    </ContentWrapper>
  )
}
export default RecordSummary
