import { Box } from '@mui/material'
import { FlexRow } from '@papertrail/styleguide'
import React from 'react'

type Props = {
  name
  value
}

const RecordField = (props: Props) => {
  const { name, value } = props
  return (
    <Box
      sx={{ borderBottom: '1px solid #ABB4C4' }}
      height={'40px'}
      display={'flex'}
      paddingBottom={'8px'}
      paddingTop={'8px'}>
      <FlexRow justifyContent={'space-between'} sx={{ flex: 1 }}>
        <Box>{name}</Box>
        <Box maxWidth={'180px'}>
          <h4>{value}</h4>
        </Box>
      </FlexRow>
    </Box>
  )
}

export default RecordField
