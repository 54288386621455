import React from 'react'
import { theme, ThemeProvider } from '@papertrail/styleguide'
import { BrowserRouter } from 'react-router-dom'
import RecordSummary from './components/RecordSummary'

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <RecordSummary />
      </BrowserRouter>
    </ThemeProvider>
  )
}
