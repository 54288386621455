import { Box } from '@mui/material'
import React from 'react'
import AppStore from '../assets/AppStore'
import PlayStore from '../assets/PlayStore'

const SummaryFooter = () => {
  const goToLink = (linkUrl) => {
    window.open(linkUrl)
  }

  return (
    <Box
      width="100%"
      paddingBottom="200px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ cursor: 'pointer', background: '#354a63' }}>
      <Box
        sx={{
          padding: '8px 16px',
          width: '100%',
          maxWidth: '340px',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Box onClick={() => goToLink('https://apps.apple.com/gb/app/papertrail-io/id1148113321')}>
          <AppStore />
        </Box>
        <Box onClick={() => goToLink('https://play.google.com/store/apps/details?id=com.papertrail.app')}>
          <PlayStore />
        </Box>
      </Box>
    </Box>
  )
}

export default SummaryFooter
