import { Box } from '@mui/material'
import { FlexRow, ButtonDanger } from '@papertrail/styleguide'
import React from 'react'
import Logo from '../assets/Logo'

const SummaryHeader = () => {
  const isMobileView = window.innerWidth <= 768
  const goToLink = (linkUrl) => {
    window.open(linkUrl)
  }

  return (
    <Box
      px={2}
      height={'80px'}
      flexShrink={0}
      sx={{ backgroundColor: '#354a63', color: 'white' }}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}>
      <FlexRow justifyContent={'space-between'} alignItems="center" sx={{ width: '100%', height: '100%' }}>
        <Box onClick={() => goToLink('https://www.papertrail.io/')} sx={{ visibility: !isMobileView && 'hidden' }}>
          <Logo width={'122px'} />
        </Box>

        <Box onClick={() => goToLink('https://www.papertrail.io/')} sx={{ visibility: isMobileView && 'hidden' }}>
          <Logo width={!isMobileView ? '200px' : '0px'} />
        </Box>

        <ButtonDanger onClick={() => goToLink('https://www.papertrail.io/book-demo')}>{'Book a demo'}</ButtonDanger>
      </FlexRow>
    </Box>
  )
}

export default SummaryHeader
