import React from 'react'

const PlayStore = () => {
  return (
    <div>
      <svg width="150" viewBox="0 0 167 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1859_134)">
          <rect x="0.805664" width="166.194" height="50.6137" rx="9" fill="white" />
          <path
            d="M40.7954 24.6613L34.4634 21.0021L29.2563 26.0431L34.0153 30.6508L40.7954 26.7319C41.1685 26.5163 41.3915 26.1294 41.3915 25.6971C41.3915 25.2649 41.1685 24.878 40.7954 24.6613Z"
            fill="#FFD109"
          />
          <path d="M33.3893 20.3797L26.4204 16.3524L11.1501 8.51559L28.3975 25.2118L33.3893 20.3797Z" fill="#06EF79" />
          <path d="M11.4041 43.3252L26.4469 35.0263L32.94 31.2721L28.3975 26.8745L11.4041 43.3252Z" fill="#F93646" />
          <path d="M10.1313 9.19104L10.1147 42.9107L27.5398 26.0431L10.1313 9.19104Z" fill="#05D5FB" />
          <path
            d="M160.576 1.10549C163.509 1.10549 165.896 3.49556 165.896 6.43284V44.1798C165.896 47.1182 163.509 49.5082 160.576 49.5082H7.23036C4.2962 49.5082 1.90956 47.1182 1.90956 44.1798V6.43284C1.90956 3.49556 4.2962 1.10549 7.23036 1.10549H160.576ZM160.576 0H7.23036C3.68132 0 0.805664 2.8798 0.805664 6.43284V44.1798C0.805664 47.7328 3.68132 50.6137 7.23036 50.6137H160.576C164.124 50.6137 167 47.7339 167 44.1798V6.43284C167 2.8798 164.124 0 160.576 0Z"
            fill="black"
          />
          <path
            d="M55.2721 30.5734H57.5903V35.8389C56.9114 36.3795 56.121 36.7951 55.2158 37.0848C54.3117 37.3744 53.3811 37.5192 52.4273 37.5192C51.0828 37.5192 49.8707 37.2263 48.79 36.6415C47.7093 36.0567 46.8615 35.2485 46.2466 34.216C45.6306 33.1846 45.3237 32.0205 45.3237 30.7249C45.3237 29.4292 45.6317 28.2652 46.2466 27.2337C46.8615 26.2023 47.7137 25.3942 48.7999 24.8094C49.8861 24.2246 51.1082 23.9316 52.4649 23.9316C53.5699 23.9316 54.5755 24.114 55.4796 24.4789C56.3837 24.8437 57.1443 25.3787 57.7592 26.0829L56.2137 27.593C55.2081 26.587 53.996 26.0829 52.5764 26.0829C51.6469 26.0829 50.8212 26.2786 50.0981 26.6677C49.375 27.058 48.8098 27.6052 48.4025 28.3094C47.9941 29.0136 47.7898 29.8195 47.7898 30.7249C47.7898 31.6303 47.9941 32.3986 48.4025 33.1028C48.811 33.807 49.3761 34.3608 50.0981 34.7632C50.82 35.1656 51.6402 35.3668 52.5576 35.3668C53.5875 35.3668 54.4916 35.1402 55.271 34.6869V30.5734H55.2721Z"
            fill="black"
          />
          <path
            d="M62.3569 36.8017C61.54 36.3618 60.9031 35.7482 60.4438 34.9622C59.9846 34.1762 59.7561 33.2863 59.7561 32.2924C59.7561 31.2986 59.9857 30.412 60.4438 29.6315C60.9019 28.8522 61.54 28.2419 62.3569 27.8008C63.1738 27.3609 64.09 27.1409 65.1078 27.1409C66.1256 27.1409 67.0606 27.3609 67.8775 27.8008C68.6944 28.2408 69.3313 28.8511 69.7906 29.6315C70.2487 30.412 70.4783 31.2986 70.4783 32.2924C70.4783 33.2863 70.2487 34.1762 69.7906 34.9622C69.3313 35.7482 68.6944 36.3618 67.8775 36.8017C67.0606 37.2417 66.1377 37.4617 65.1078 37.4617C64.0779 37.4617 63.1727 37.2417 62.3569 36.8017ZM67.256 34.5753C67.8212 33.9971 68.1038 33.2354 68.1038 32.2924C68.1038 31.3495 67.8212 30.5878 67.256 30.0096C66.6908 29.4314 65.9744 29.1418 65.1078 29.1418C64.2412 29.1418 63.5281 29.4314 62.9696 30.0096C62.4099 30.5889 62.1306 31.3495 62.1306 32.2924C62.1306 33.2354 62.4099 33.9971 62.9696 34.5753C63.5281 35.1546 64.2412 35.4431 65.1078 35.4431C65.9744 35.4431 66.6908 35.1535 67.256 34.5753Z"
            fill="black"
          />
          <path
            d="M74.5109 36.8017C73.6941 36.3618 73.0571 35.7482 72.5979 34.9622C72.1387 34.1762 71.9102 33.2863 71.9102 32.2924C71.9102 31.2986 72.1387 30.412 72.5979 29.6315C73.056 28.8522 73.6941 28.2419 74.5109 27.8008C75.3278 27.3609 76.2441 27.1409 77.2619 27.1409C78.2797 27.1409 79.2147 27.3609 80.0315 27.8008C80.8484 28.2408 81.4854 28.8511 81.9446 29.6315C82.4027 30.412 82.6323 31.2986 82.6323 32.2924C82.6323 33.2863 82.4027 34.1762 81.9446 34.9622C81.4854 35.7482 80.8484 36.3618 80.0315 36.8017C79.2147 37.2417 78.2918 37.4617 77.2619 37.4617C76.2319 37.4617 75.3267 37.2417 74.5109 36.8017ZM79.4101 34.5753C79.9753 33.9971 80.2578 33.2354 80.2578 32.2924C80.2578 31.3495 79.9753 30.5878 79.4101 30.0096C78.8449 29.4314 78.1284 29.1418 77.2619 29.1418C76.3953 29.1418 75.6822 29.4314 75.1236 30.0096C74.5639 30.5889 74.2846 31.3495 74.2846 32.2924C74.2846 33.2354 74.5639 33.9971 75.1236 34.5753C75.6822 35.1546 76.3953 35.4431 77.2619 35.4431C78.1284 35.4431 78.8449 35.1535 79.4101 34.5753Z"
            fill="black"
          />
          <path
            d="M94.8801 27.2525V35.8013C94.8801 39.3488 93.0708 41.1231 89.4533 41.1231C88.4863 41.1231 87.569 41.0004 86.7024 40.755C85.8358 40.5096 85.1194 40.1536 84.5542 39.6893L85.6095 37.915C86.0489 38.2798 86.6053 38.5727 87.2775 38.7927C87.9498 39.0127 88.6309 39.1233 89.322 39.1233C90.427 39.1233 91.2372 38.8712 91.7527 38.3682C92.2683 37.8652 92.5255 37.098 92.5255 36.0655V35.5371C92.1237 35.9771 91.6335 36.3109 91.0562 36.5375C90.4777 36.7642 89.8441 36.8769 89.1531 36.8769C88.1982 36.8769 87.3349 36.6724 86.5622 36.2634C85.7895 35.8543 85.1801 35.2795 84.7341 34.5366C84.2882 33.7948 84.0652 32.9458 84.0652 31.9896C84.0652 31.0333 84.2882 30.1876 84.7341 29.4513C85.1801 28.7151 85.7895 28.1469 86.5622 27.7434C87.3349 27.341 88.1982 27.1398 89.1531 27.1398C89.8816 27.1398 90.544 27.2592 91.1412 27.4979C91.7373 27.7367 92.2374 28.1015 92.6392 28.5924V27.2525H94.8812H94.8801ZM91.7053 34.0745C92.2771 33.5395 92.563 32.8452 92.563 31.9896C92.563 31.1339 92.2771 30.4607 91.7053 29.9322C91.1335 29.4038 90.4016 29.1396 89.5096 29.1396C88.6177 29.1396 87.867 29.4038 87.2952 29.9322C86.7234 30.4607 86.4375 31.1461 86.4375 31.9896C86.4375 32.833 86.7234 33.5406 87.2952 34.0745C87.867 34.6096 88.6044 34.876 89.5096 34.876C90.4148 34.876 91.1335 34.6085 91.7053 34.0745Z"
            fill="black"
          />
          <path d="M97.9709 23.3269H100.327V37.3291H97.9709V23.3269Z" fill="black" />
          <path
            d="M112.838 33.0464H104.943C105.081 33.7893 105.442 34.3763 106.027 34.8108C106.611 35.2452 107.336 35.4619 108.204 35.4619C109.309 35.4619 110.22 35.0971 110.936 34.3675L112.199 35.8201C111.747 36.3607 111.175 36.7697 110.485 37.0472C109.794 37.3235 109.016 37.4628 108.148 37.4628C107.042 37.4628 106.068 37.2428 105.227 36.8029C104.385 36.3629 103.736 35.7493 103.276 34.9633C102.817 34.1773 102.589 33.2874 102.589 32.2936C102.589 31.2997 102.812 30.4286 103.258 29.6426C103.704 28.8566 104.322 28.2431 105.113 27.8031C105.905 27.3631 106.797 27.1431 107.789 27.1431C108.782 27.1431 109.645 27.3598 110.417 27.7942C111.19 28.2287 111.793 28.8389 112.227 29.6249C112.661 30.4109 112.877 31.3207 112.877 32.3522C112.877 32.5158 112.865 32.7479 112.839 33.0508L112.838 33.0464ZM105.876 29.6968C105.355 30.1434 105.037 30.7381 104.924 31.4799H110.634C110.533 30.7503 110.225 30.1589 109.711 29.7056C109.195 29.2524 108.555 29.0257 107.789 29.0257C107.023 29.0257 106.397 29.2491 105.876 29.6957V29.6968Z"
            fill="black"
          />
          <path
            d="M128.977 24.6867C129.825 25.0636 130.475 25.6053 130.928 26.3095C131.38 27.0137 131.607 27.8506 131.607 28.819C131.607 29.7874 131.38 30.6088 130.928 31.3196C130.475 32.0304 129.825 32.5743 128.977 32.9524C128.129 33.3294 127.134 33.5184 125.99 33.5184H123.013V37.3302H120.563V24.1207H125.99C127.133 24.1207 128.128 24.3097 128.977 24.6867ZM128.308 30.7636C128.861 30.3103 129.137 29.6625 129.137 28.8201C129.137 27.9777 128.861 27.3288 128.308 26.8767C127.755 26.4234 126.945 26.1968 125.877 26.1968H123.013V31.4423H125.877C126.945 31.4423 127.755 31.2157 128.308 30.7624V30.7636Z"
            fill="black"
          />
          <path d="M134.018 23.3269H136.373V37.3291H134.018V23.3269Z" fill="black" />
          <path
            d="M146.708 28.2055C147.493 28.9163 147.886 29.9886 147.886 31.4224V37.3291H145.663V36.102C145.373 36.542 144.962 36.8791 144.428 37.1113C143.894 37.3434 143.251 37.4606 142.497 37.4606C141.743 37.4606 141.084 37.3313 140.518 37.0737C139.953 36.8161 139.516 36.4579 139.209 35.9981C138.901 35.5393 138.748 35.0197 138.748 34.4415C138.748 33.5361 139.083 32.8087 139.756 32.2615C140.428 31.7143 141.487 31.4401 142.93 31.4401H145.531V31.2887C145.531 30.5845 145.32 30.0428 144.9 29.6658C144.479 29.2888 143.854 29.0998 143.025 29.0998C142.46 29.0998 141.904 29.1882 141.357 29.364C140.811 29.5409 140.35 29.7852 139.972 30.1003L139.049 28.3835C139.577 27.9811 140.21 27.6726 140.952 27.4593C141.693 27.2459 142.479 27.1387 143.308 27.1387C144.791 27.1387 145.924 27.4946 146.709 28.2044L146.708 28.2055ZM144.56 35.3381C145.018 35.0672 145.341 34.6803 145.53 34.1773V33.0077H143.099C141.743 33.0077 141.064 33.4543 141.064 34.3476C141.064 34.7754 141.234 35.1148 141.573 35.3668C141.912 35.6189 142.383 35.7438 142.986 35.7438C143.588 35.7438 144.101 35.6089 144.559 35.3381H144.56Z"
            fill="black"
          />
          <path
            d="M160.135 27.2525L155.425 38.1405C154.985 39.2349 154.451 40.0055 153.823 40.4521C153.195 40.8987 152.434 41.122 151.543 41.122C151.041 41.122 150.544 41.0402 150.054 40.8766C149.564 40.713 149.162 40.4863 148.849 40.1967L149.791 38.4611C150.018 38.6745 150.284 38.8447 150.592 38.9707C150.9 39.0967 151.21 39.1598 151.524 39.1598C151.94 39.1598 152.282 39.0525 152.551 38.8392C152.822 38.6247 153.069 38.2665 153.295 37.7635L153.465 37.3678L149.075 27.2525H151.524L154.69 34.7068L157.875 27.2525H160.136H160.135Z"
            fill="black"
          />
          <path
            d="M49.5385 16.7868C49.0064 16.7868 48.5108 16.6708 48.0505 16.4386C47.5901 16.2065 47.1894 15.8947 46.8483 15.5023C46.5061 15.1109 46.2401 14.6687 46.0502 14.1779C45.8603 13.6871 45.7654 13.1785 45.7654 12.6534C45.7654 12.1283 45.8603 11.5866 46.0502 11.0958C46.2401 10.605 46.5039 10.1694 46.8428 9.7891C47.1806 9.40881 47.5802 9.11033 48.0394 8.89255C48.4986 8.67587 48.9987 8.56754 49.5385 8.56754C50.3212 8.56754 50.967 8.73115 51.4759 9.05837C51.9848 9.3856 52.3689 9.81232 52.6272 10.3374L51.8412 10.8857C51.6061 10.4137 51.2827 10.0644 50.872 9.83553C50.4614 9.6067 50.0099 9.49283 49.5153 9.49283C49.1047 9.49283 48.7327 9.58016 48.3982 9.75483C48.0637 9.9295 47.7767 10.1683 47.5371 10.4679C47.2976 10.7686 47.1155 11.1091 46.9896 11.4893C46.8638 11.8696 46.8019 12.2654 46.8019 12.6766C46.8019 13.11 46.8726 13.519 47.0128 13.9037C47.153 14.2884 47.3528 14.6267 47.6111 14.9197C47.8694 15.2126 48.1697 15.4426 48.5119 15.6106C48.8541 15.7775 49.2228 15.8616 49.618 15.8616C50.0816 15.8616 50.5221 15.7433 50.9405 15.5078C51.3588 15.2723 51.754 14.9108 52.1261 14.4233V15.4846C51.3964 16.3524 50.5342 16.7857 49.5385 16.7857V16.7868ZM52.0002 13.4649H50.256V12.6888H52.8668V16.7294H52.0002V13.4649Z"
            fill="black"
          />
          <path
            d="M59.9218 15.8162V16.7294H54.4277V8.62503H59.8192V9.53817H55.4533V12.1637H59.2496V13.0194H55.4533V15.8162H59.9218Z"
            fill="black"
          />
          <path d="M67.1601 9.53817H64.3672V16.7294H63.3417V9.53817H60.5488V8.62503H67.1601V9.53817Z" fill="black" />
          <path d="M70.8647 16.7294V8.62503H71.8903V16.7294H70.8647Z" fill="black" />
          <path
            d="M74.8541 10.5198V16.7294H73.8286V8.62503H74.6952L79.6417 14.9606V8.63609H80.6673V16.7294H79.7323L74.8541 10.5198Z"
            fill="black"
          />
          <path
            d="M88.7723 16.7868C88.2027 16.7868 87.6861 16.673 87.2224 16.4441C86.7588 16.2153 86.3581 15.9058 86.0203 15.5133C85.6825 15.1209 85.422 14.6798 85.2398 14.189C85.0577 13.6981 84.9661 13.194 84.9661 12.6766C84.9661 12.1593 85.0632 11.6209 85.2564 11.1301C85.4507 10.6392 85.72 10.2014 86.0655 9.81784C86.4111 9.43313 86.8162 9.12912 87.2798 8.90471C87.7435 8.6803 88.2491 8.56754 88.7955 8.56754C89.3419 8.56754 89.8817 8.68582 90.3454 8.92129C90.809 9.15676 91.2075 9.47293 91.542 9.8687C91.8765 10.2645 92.1348 10.7056 92.3169 11.1931C92.4991 11.6806 92.5907 12.1792 92.5907 12.6888C92.5907 13.2294 92.4958 13.7445 92.3059 14.2354C92.116 14.7262 91.8478 15.164 91.5023 15.5476C91.1567 15.9323 90.7516 16.2341 90.288 16.4552C89.8243 16.6763 89.3187 16.7857 88.7723 16.7857V16.7868ZM86.0026 12.6777C86.0026 13.089 86.0689 13.487 86.2024 13.8706C86.3349 14.2553 86.5237 14.5958 86.7665 14.892C87.0094 15.1883 87.3019 15.4249 87.6441 15.5996C87.9863 15.7742 88.3661 15.8616 88.7845 15.8616C89.2028 15.8616 89.6047 15.7698 89.9469 15.5874C90.2891 15.405 90.5794 15.1618 90.8189 14.8567C91.0585 14.5527 91.2406 14.21 91.3665 13.8297C91.4923 13.4494 91.5541 13.0647 91.5541 12.6766C91.5541 12.2654 91.4879 11.8696 91.3543 11.4893C91.2219 11.1091 91.0309 10.7686 90.7847 10.4679C90.5374 10.1672 90.2449 9.93171 89.9071 9.76036C89.5693 9.58901 89.1951 9.50389 88.7845 9.50389C88.3517 9.50389 87.9643 9.59343 87.622 9.77252C87.2798 9.95161 86.9895 10.1926 86.75 10.4977C86.5104 10.8017 86.3261 11.1422 86.1969 11.5192C86.0678 11.8962 86.0026 12.282 86.0026 12.6777Z"
            fill="black"
          />
          <path
            d="M95.0411 10.5198V16.7294H94.0156V8.62503H94.8822L99.8299 14.9606V8.63609H100.855V16.7294H99.9204L95.0423 10.5198H95.0411Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1859_134">
            <rect x="0.805664" width="166.194" height="50.6137" rx="9" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default PlayStore
