import React from 'react'

const AppStore = () => {
  return (
    <div>
      <svg width="150" viewBox="0 0 169 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1859_102)">
          <rect width="168.076" height="50.9551" rx="9" fill="white" />
          <path
            d="M161.579 1.11295C164.546 1.11295 166.959 3.51913 166.959 6.47623V44.4778C166.959 47.436 164.546 49.8422 161.579 49.8422H6.49631C3.53005 49.8422 1.1164 47.436 1.1164 44.4778V6.47623C1.1164 3.51913 3.53005 1.11295 6.49631 1.11295H161.578M161.578 0H6.49631C2.90821 0 0 2.89922 0 6.47623V44.4778C0 48.0548 2.90821 50.9551 6.49631 50.9551H161.578C165.166 50.9551 168.075 48.0559 168.075 44.4778V6.47623C168.075 2.89922 165.166 0 161.578 0Z"
            fill="black"
          />
          <path
            d="M17.697 20.8199C14.9708 25.5365 16.7034 32.695 19.7188 37.0344C21.2237 39.2046 22.7476 41.1523 24.8341 41.1523C24.8732 41.1523 24.9123 41.1523 24.9536 41.1501C25.9338 41.1111 26.6427 40.8106 27.3282 40.519C28.0974 40.1918 28.8934 39.8546 30.1415 39.8546C31.3896 39.8546 32.0862 40.1818 32.8186 40.4968C33.5409 40.8084 34.2844 41.1311 35.383 41.11C37.7341 41.0666 39.1776 38.9642 40.4503 37.1101C41.7788 35.1746 42.4464 33.2949 42.6708 32.5759L42.6797 32.547C42.7222 32.419 42.6619 32.2799 42.5391 32.2242C42.5391 32.2242 42.5234 32.2176 42.5201 32.2164C42.1059 32.0473 38.4743 30.4413 38.4363 26.0641C38.4006 22.5071 41.1603 20.6251 41.7141 20.2834L41.7397 20.2667C41.8 20.2289 41.8413 20.1677 41.8558 20.0976C41.8704 20.0275 41.8558 19.9551 41.8145 19.8961C39.91 17.1171 36.9906 16.6986 35.815 16.6486C35.6453 16.6319 35.4689 16.623 35.2925 16.623C33.9116 16.623 32.5897 17.1427 31.5269 17.5601C30.7935 17.8483 30.1593 18.0976 29.724 18.0976C29.2327 18.0976 28.5953 17.845 27.8585 17.5545C26.8716 17.1638 25.7529 16.722 24.5696 16.722C24.5416 16.722 24.5137 16.722 24.4858 16.7231C21.7328 16.7632 19.1316 18.3336 17.6981 20.8188L17.697 20.8199Z"
            fill="black"
          />
          <path
            d="M35.8173 9.0015C34.1505 9.06939 32.151 10.0922 30.9565 11.4878C29.9406 12.6598 28.9481 14.6163 29.2093 16.5873C29.2261 16.7109 29.3265 16.8055 29.4516 16.8155C29.5643 16.8244 29.6782 16.8288 29.7932 16.8288C31.4231 16.8288 33.1815 15.9296 34.3816 14.4828C35.6454 12.9547 36.2839 10.9937 36.0908 9.23633C36.0752 9.09832 35.9535 8.99593 35.8173 9.0015Z"
            fill="black"
          />
          <path
            d="M54.9949 10.0154H58.1554C58.9849 10.0154 59.7206 10.1857 60.3636 10.5273C61.0067 10.869 61.5068 11.3409 61.8641 11.9441C62.2213 12.5473 62.3999 13.2318 62.3999 13.9975C62.3999 14.7632 62.2213 15.4477 61.8641 16.0509C61.5068 16.6541 61.0067 17.126 60.3636 17.4666C59.7206 17.8082 58.9849 17.9785 58.1554 17.9785H54.9949V10.0154ZM58.1096 17.4554C58.8476 17.4554 59.4973 17.3096 60.0611 17.0169C60.6237 16.7253 61.058 16.3169 61.3617 15.7938C61.6653 15.2707 61.8183 14.6719 61.8183 13.9964C61.8183 13.3208 61.6665 12.7221 61.3617 12.199C61.0569 11.6759 60.6237 11.2686 60.0611 10.9759C59.4984 10.6843 58.8476 10.5385 58.1096 10.5385H55.5765V17.4543H58.1096V17.4554Z"
            fill="black"
          />
          <path
            d="M65.0424 17.6368C64.5903 17.3786 64.2342 17.0203 63.9752 16.5617C63.7162 16.1032 63.5867 15.5857 63.5867 15.0092C63.5867 14.4327 63.7162 13.9151 63.9752 13.4566C64.2342 12.9981 64.5892 12.6397 65.0424 12.3815C65.4946 12.1233 66.0026 11.9942 66.5663 11.9942C67.1301 11.9942 67.637 12.1233 68.0902 12.3815C68.5424 12.6397 68.8985 12.9981 69.1575 13.4566C69.4165 13.9151 69.546 14.4327 69.546 15.0092C69.546 15.5857 69.4165 16.1032 69.1575 16.5617C68.8985 17.0203 68.5435 17.3786 68.0902 17.6368C67.6381 17.895 67.1301 18.0241 66.5663 18.0241C66.0026 18.0241 65.4957 17.895 65.0424 17.6368ZM67.7921 17.1928C68.1538 16.9802 68.4385 16.6853 68.6484 16.3058C68.8572 15.9262 68.9621 15.4944 68.9621 15.0092C68.9621 14.5239 68.8572 14.0921 68.6484 13.7126C68.4385 13.3331 68.1538 13.0381 67.7921 12.8256C67.4304 12.613 67.0218 12.5073 66.5652 12.5073C66.1086 12.5073 65.7 12.613 65.3383 12.8256C64.9766 13.0381 64.6919 13.3342 64.482 13.7126C64.2721 14.0921 64.1683 14.5239 64.1683 15.0092C64.1683 15.4944 64.2733 15.9274 64.482 16.3058C64.6908 16.6853 64.9766 16.9813 65.3383 17.1928C65.7 17.4053 66.1086 17.5111 66.5652 17.5111C67.0218 17.5111 67.4304 17.4053 67.7921 17.1928Z"
            fill="black"
          />
          <path
            d="M79.6539 12.0398L77.3943 17.9774H76.8584L74.85 12.8245L72.8304 17.9774H72.3057L70.0461 12.0398H70.5943L72.5792 17.3408L74.61 12.0398H75.1012L77.1207 17.3297L79.1291 12.0398H79.6539Z"
            fill="black"
          />
          <path
            d="M85.6556 12.6431C86.0888 13.0749 86.3065 13.697 86.3065 14.5084V17.9774H85.736V14.5529C85.736 13.8851 85.5663 13.3776 85.228 13.0281C84.8898 12.6798 84.4164 12.5051 83.8069 12.5051C83.1069 12.5051 82.5554 12.7143 82.1524 13.1305C81.7493 13.5479 81.5473 14.1088 81.5473 14.8144V17.9763H80.9768V12.0387H81.525V13.3242C81.7304 12.9068 82.0363 12.5807 82.4437 12.3459C82.8501 12.1111 83.3324 11.9931 83.8872 11.9931C84.633 11.9931 85.2225 12.209 85.6556 12.6419V12.6431Z"
            fill="black"
          />
          <path d="M88.6565 9.53793H89.227V17.9785H88.6565V9.53793Z" fill="black" />
          <path
            d="M92.4389 17.6368C91.9857 17.3786 91.6307 17.0203 91.3717 16.5617C91.1127 16.1032 90.9832 15.5857 90.9832 15.0092C90.9832 14.4327 91.1127 13.9151 91.3717 13.4566C91.6307 12.9981 91.9857 12.6397 92.4389 12.3815C92.8911 12.1233 93.399 11.9942 93.9617 11.9942C94.5244 11.9942 95.0323 12.1233 95.4856 12.3815C95.9377 12.6397 96.2939 12.9981 96.5529 13.4566C96.8119 13.9151 96.9403 14.4327 96.9403 15.0092C96.9403 15.5857 96.8107 16.1032 96.5529 16.5617C96.2939 17.0203 95.9388 17.3786 95.4856 17.6368C95.0323 17.895 94.5255 18.0241 93.9617 18.0241C93.3979 18.0241 92.8911 17.895 92.4389 17.6368ZM95.1886 17.1928C95.5503 16.9802 95.835 16.6853 96.0449 16.3058C96.2537 15.9262 96.3586 15.4944 96.3586 15.0092C96.3586 14.5239 96.2537 14.0921 96.0449 13.7126C95.835 13.3331 95.5503 13.0381 95.1886 12.8256C94.8269 12.613 94.4183 12.5073 93.9617 12.5073C93.5051 12.5073 93.0965 12.613 92.7348 12.8256C92.3731 13.0381 92.0884 13.3342 91.8785 13.7126C91.6686 14.0921 91.5648 14.5239 91.5648 15.0092C91.5648 15.4944 91.6697 15.9274 91.8785 16.3058C92.0873 16.6853 92.3731 16.9813 92.7348 17.1928C93.0965 17.4053 93.5051 17.5111 93.9617 17.5111C94.4183 17.5111 94.8269 17.4053 95.1886 17.1928Z"
            fill="black"
          />
          <path
            d="M102.395 12.5573C102.79 12.9324 102.988 13.4844 102.988 14.2123V17.9774H102.44V16.919C102.25 17.2673 101.972 17.5389 101.607 17.7325C101.242 17.9262 100.804 18.023 100.295 18.023C99.633 18.023 99.1105 17.8672 98.7265 17.5567C98.3424 17.2462 98.1504 16.8366 98.1504 16.328C98.1504 15.8194 98.3268 15.4354 98.6807 15.1282C99.0346 14.8211 99.5995 14.6675 100.375 14.6675H102.418V14.19C102.418 13.6369 102.268 13.2162 101.967 12.9268C101.667 12.6386 101.227 12.495 100.649 12.495C100.254 12.495 99.8752 12.5629 99.5135 12.6998C99.1518 12.8367 98.8459 13.0181 98.5947 13.2463L98.3089 12.8367C98.6059 12.5718 98.9631 12.3648 99.3818 12.2168C99.8004 12.0688 100.238 11.9953 100.694 11.9953C101.431 11.9953 101.999 12.1834 102.394 12.5585L102.395 12.5573ZM101.636 17.2161C101.982 16.9891 102.242 16.6585 102.417 16.2267V15.1115H100.387C99.8004 15.1115 99.3762 15.2173 99.1138 15.4299C98.8515 15.6424 98.7198 15.934 98.7198 16.3057C98.7198 16.6775 98.8638 16.998 99.1529 17.2217C99.4421 17.4454 99.8484 17.5578 100.374 17.5578C100.869 17.5578 101.289 17.4443 101.635 17.2161H101.636Z"
            fill="black"
          />
          <path
            d="M110.645 9.53793V17.9785H110.086V16.5795C109.85 17.0425 109.519 17.3987 109.093 17.6491C108.667 17.8995 108.185 18.0241 107.644 18.0241C107.104 18.0241 106.587 17.895 106.138 17.6368C105.689 17.3786 105.338 17.0225 105.083 16.5673C104.829 16.1121 104.701 15.5935 104.701 15.0092C104.701 14.4249 104.829 13.904 105.083 13.4455C105.338 12.987 105.689 12.6308 106.138 12.3759C106.587 12.1222 107.089 11.9953 107.644 11.9953C108.199 11.9953 108.655 12.12 109.077 12.3704C109.499 12.6208 109.831 12.9691 110.075 13.4165V9.53793H110.645ZM108.905 17.1939C109.266 16.9813 109.551 16.6864 109.761 16.3069C109.97 15.9274 110.075 15.4955 110.075 15.0103C110.075 14.525 109.97 14.0932 109.761 13.7137C109.551 13.3342 109.266 13.0393 108.905 12.8267C108.543 12.6141 108.134 12.5084 107.678 12.5084C107.221 12.5084 106.813 12.6141 106.451 12.8267C106.089 13.0393 105.804 13.3353 105.595 13.7137C105.385 14.0932 105.281 14.525 105.281 15.0103C105.281 15.4955 105.386 15.9285 105.595 16.3069C105.803 16.6864 106.089 16.9824 106.451 17.1939C106.813 17.4065 107.221 17.5122 107.678 17.5122C108.134 17.5122 108.543 17.4065 108.905 17.1939Z"
            fill="black"
          />
          <path
            d="M116.79 17.6368C116.338 17.3786 115.981 17.0203 115.722 16.5617C115.463 16.1032 115.334 15.5857 115.334 15.0092C115.334 14.4327 115.463 13.9151 115.722 13.4566C115.981 12.9981 116.337 12.6397 116.79 12.3815C117.242 12.1233 117.75 11.9942 118.314 11.9942C118.877 11.9942 119.384 12.1233 119.836 12.3815C120.289 12.6397 120.645 12.9981 120.904 13.4566C121.163 13.9151 121.291 14.4327 121.291 15.0092C121.291 15.5857 121.162 16.1032 120.904 16.5617C120.645 17.0203 120.29 17.3786 119.836 17.6368C119.384 17.895 118.876 18.0241 118.314 18.0241C117.751 18.0241 117.243 17.895 116.79 17.6368ZM119.539 17.1928C119.901 16.9802 120.186 16.6853 120.396 16.3058C120.605 15.9262 120.709 15.4944 120.709 15.0092C120.709 14.5239 120.605 14.0921 120.396 13.7126C120.186 13.3331 119.901 13.0381 119.539 12.8256C119.178 12.613 118.769 12.5073 118.313 12.5073C117.856 12.5073 117.447 12.613 117.086 12.8256C116.724 13.0381 116.439 13.3342 116.229 13.7126C116.019 14.0921 115.916 14.5239 115.916 15.0092C115.916 15.4944 116.021 15.9274 116.229 16.3058C116.438 16.6853 116.724 16.9813 117.086 17.1928C117.447 17.4053 117.856 17.5111 118.313 17.5111C118.769 17.5111 119.178 17.4053 119.539 17.1928Z"
            fill="black"
          />
          <path
            d="M127.738 12.6431C128.171 13.0749 128.389 13.697 128.389 14.5084V17.9774H127.819V14.5529C127.819 13.8851 127.649 13.3776 127.311 13.0281C126.972 12.6798 126.499 12.5051 125.889 12.5051C125.189 12.5051 124.638 12.7143 124.235 13.1305C123.832 13.5479 123.63 14.1088 123.63 14.8144V17.9763H123.059V12.0387H123.607V13.3242C123.813 12.9068 124.119 12.5807 124.526 12.3459C124.933 12.1111 125.415 11.9931 125.97 11.9931C126.716 11.9931 127.305 12.209 127.738 12.6419V12.6431Z"
            fill="black"
          />
          <path
            d="M136.626 17.6023C136.481 17.7392 136.301 17.8427 136.083 17.9151C135.867 17.9874 135.64 18.023 135.405 18.023C134.887 18.023 134.488 17.8806 134.207 17.5968C133.925 17.3118 133.785 16.9167 133.785 16.4081V12.5295H132.689V12.0409H133.785V10.7444H134.355V12.0409H136.25V12.5295H134.355V16.3514C134.355 16.7376 134.448 17.0325 134.634 17.2328C134.821 17.4343 135.096 17.5344 135.461 17.5344C135.827 17.5344 136.142 17.4243 136.386 17.205L136.626 17.6034V17.6023Z"
            fill="black"
          />
          <path
            d="M142.891 12.643C143.324 13.0749 143.542 13.697 143.542 14.5083V17.9774H142.971V14.5529C142.971 13.8851 142.802 13.3776 142.463 13.0281C142.125 12.6798 141.652 12.505 141.042 12.505C140.342 12.505 139.791 12.7143 139.388 13.1305C138.985 13.5479 138.783 14.1088 138.783 14.8144V17.9763H138.212V9.53571H138.783V13.2785C138.988 12.869 139.292 12.5529 139.696 12.3292C140.099 12.1055 140.574 11.9931 141.123 11.9931C141.868 11.9931 142.458 12.209 142.891 12.6419V12.643Z"
            fill="black"
          />
          <path
            d="M150.935 15.1461H145.812C145.827 15.609 145.945 16.0197 146.166 16.3803C146.387 16.7409 146.685 17.0192 147.061 17.2161C147.437 17.4131 147.861 17.5122 148.334 17.5122C148.721 17.5122 149.08 17.4421 149.412 17.3018C149.742 17.1616 150.018 16.9546 150.239 16.6819L150.57 17.057C150.311 17.3686 149.986 17.6068 149.594 17.7737C149.202 17.9407 148.778 18.0241 148.321 18.0241C147.727 18.0241 147.199 17.895 146.735 17.6368C146.27 17.3786 145.908 17.0225 145.645 16.5673C145.383 16.1121 145.251 15.5935 145.251 15.0092C145.251 14.4249 145.375 13.9151 145.622 13.4566C145.869 12.9981 146.209 12.6397 146.643 12.3815C147.077 12.1233 147.563 11.9942 148.104 11.9942C148.644 11.9942 149.128 12.1211 149.558 12.3748C149.988 12.6286 150.326 12.9836 150.574 13.4388C150.821 13.894 150.945 14.4093 150.945 14.9858L150.934 15.145L150.935 15.1461ZM146.975 12.7744C146.64 12.9602 146.372 13.2218 146.17 13.559C145.968 13.8962 145.852 14.278 145.822 14.702H150.386C150.363 14.2769 150.248 13.8985 150.043 13.5646C149.837 13.2307 149.567 12.9691 149.232 12.7799C148.897 12.5907 148.521 12.495 148.103 12.495C147.684 12.495 147.308 12.5885 146.973 12.7733L146.975 12.7744Z"
            fill="black"
          />
          <path
            d="M63.4437 35.5809H56.6191L55.2739 38.7305H52.6604L58.8028 25.1225H61.2991L67.4605 38.7305H64.809L63.4437 35.5809ZM62.6053 33.5976L60.0308 27.6489L57.4765 33.5976H62.6053Z"
            fill="black"
          />
          <path
            d="M77.4534 28.8943C78.2527 29.335 78.8801 29.9571 79.3356 30.7607C79.79 31.5642 80.0177 32.4913 80.0177 33.5408C80.0177 34.5903 79.79 35.5208 79.3356 36.3299C78.8801 37.1401 78.2538 37.7656 77.4534 38.2063C76.654 38.647 75.7475 38.8674 74.7327 38.8674C73.3283 38.8674 72.2175 38.4011 71.398 37.4673V42.5023H68.9609V28.35H71.2819V29.7112C71.6849 29.2193 72.1817 28.8498 72.7734 28.6027C73.3651 28.3567 74.0182 28.2332 74.7327 28.2332C75.7464 28.2332 76.6529 28.4535 77.4534 28.8943ZM76.673 35.8925C77.2513 35.2971 77.5404 34.5124 77.5404 33.5408C77.5404 32.5692 77.2513 31.7846 76.673 31.1881C76.0947 30.5926 75.3568 30.2944 74.4603 30.2944C73.8753 30.2944 73.3484 30.4279 72.8806 30.6928C72.4128 30.9588 72.0422 31.3372 71.7687 31.8302C71.4952 32.3233 71.359 32.8931 71.359 33.5408C71.359 34.1886 71.4952 34.7595 71.7687 35.2514C72.0422 35.7445 72.4117 36.1229 72.8806 36.3889C73.3484 36.6548 73.8753 36.7873 74.4603 36.7873C75.3568 36.7873 76.0947 36.489 76.673 35.8936V35.8925Z"
            fill="black"
          />
          <path
            d="M90.8301 28.8943C91.6294 29.335 92.2568 29.9571 92.7123 30.7607C93.1667 31.5642 93.3944 32.4913 93.3944 33.5408C93.3944 34.5903 93.1667 35.5208 92.7123 36.3299C92.2568 37.1401 91.6305 37.7656 90.8301 38.2063C90.0307 38.647 89.1242 38.8674 88.1094 38.8674C86.705 38.8674 85.5942 38.4011 84.7747 37.4673V42.5023H82.3376V28.35H84.6586V29.7112C85.0617 29.2193 85.5584 28.8498 86.1501 28.6027C86.7418 28.3567 87.3949 28.2332 88.1094 28.2332C89.1231 28.2332 90.0296 28.4535 90.8301 28.8943ZM90.0497 35.8925C90.628 35.2971 90.9172 34.5124 90.9172 33.5408C90.9172 32.5692 90.628 31.7846 90.0497 31.1881C89.4714 30.5926 88.7335 30.2944 87.837 30.2944C87.252 30.2944 86.7251 30.4279 86.2573 30.6928C85.7895 30.9588 85.4189 31.3372 85.1454 31.8302C84.8719 32.3233 84.7357 32.8931 84.7357 33.5408C84.7357 34.1886 84.8719 34.7595 85.1454 35.2514C85.4189 35.7445 85.7884 36.1229 86.2573 36.3889C86.7251 36.6548 87.252 36.7873 87.837 36.7873C88.7335 36.7873 89.4714 36.489 90.0497 35.8936V35.8925Z"
            fill="black"
          />
          <path
            d="M102.51 38.4779C101.529 38.1796 100.752 37.7845 100.18 37.2926L101.057 35.3293C101.617 35.7701 102.302 36.1295 103.115 36.4078C103.928 36.6871 104.743 36.8263 105.562 36.8263C106.576 36.8263 107.333 36.6649 107.834 36.3399C108.334 36.016 108.584 35.5887 108.584 35.0567C108.584 34.6682 108.445 34.3466 108.164 34.094C107.884 33.8413 107.53 33.6443 107.102 33.5008C106.673 33.3583 106.088 33.1969 105.347 33.0144C104.306 32.7685 103.464 32.5225 102.821 32.2754C102.178 32.0295 101.626 31.6433 101.163 31.1191C100.702 30.5938 100.471 29.8848 100.471 28.99C100.471 28.2388 100.676 27.5543 101.085 26.9388C101.495 26.3234 102.112 25.8337 102.937 25.4709C103.762 25.108 104.774 24.9266 105.97 24.9266C106.801 24.9266 107.621 25.0301 108.427 25.2371C109.233 25.4442 109.928 25.7424 110.513 26.1308L109.714 28.0941C109.116 27.7446 108.492 27.4786 107.842 27.2972C107.192 27.1158 106.561 27.0257 105.951 27.0257C104.949 27.0257 104.206 27.1937 103.718 27.5309C103.23 27.8682 102.987 28.3156 102.987 28.872C102.987 29.2604 103.126 29.5787 103.406 29.8247C103.685 30.0707 104.04 30.2654 104.469 30.4079C104.898 30.5504 105.483 30.7128 106.224 30.8942C107.238 31.128 108.07 31.3706 108.72 31.6232C109.37 31.8759 109.926 32.2621 110.387 32.7796C110.848 33.2982 111.079 33.9983 111.079 34.8786C111.079 35.6298 110.875 36.311 110.465 36.9197C110.056 37.5285 109.435 38.0149 108.603 38.3777C107.771 38.7405 106.757 38.9219 105.561 38.9219C104.508 38.9219 103.491 38.7728 102.509 38.4745L102.51 38.4779Z"
            fill="black"
          />
          <path
            d="M119.621 38.1674C119.335 38.4011 118.987 38.5758 118.578 38.6927C118.168 38.8095 117.736 38.8674 117.281 38.8674C116.137 38.8674 115.254 38.5691 114.629 37.9737C114.005 37.3783 113.694 36.5091 113.694 35.3683V30.3723H111.978V28.428H113.694V26.0563H116.131V28.428H118.92V30.3723H116.131V35.3104C116.131 35.8157 116.254 36.2019 116.502 36.4668C116.748 36.7328 117.106 36.8652 117.575 36.8652C118.12 36.8652 118.576 36.7227 118.94 36.4378L119.622 38.1685L119.621 38.1674Z"
            fill="black"
          />
          <path
            d="M123.112 38.1863C122.267 37.7333 121.607 37.1011 121.132 36.2909C120.658 35.4807 120.42 34.5636 120.42 33.5397C120.42 32.5158 120.657 31.6021 121.132 30.7985C121.607 29.995 122.267 29.3662 123.112 28.9132C123.957 28.4602 124.906 28.2332 125.959 28.2332C127.011 28.2332 127.98 28.4602 128.826 28.9132C129.671 29.3673 130.33 29.995 130.805 30.7985C131.279 31.6021 131.516 32.5158 131.516 33.5397C131.516 34.5636 131.278 35.4807 130.805 36.2909C130.33 37.1011 129.671 37.7333 128.826 38.1863C127.98 38.6403 127.025 38.8663 125.959 38.8663C124.892 38.8663 123.957 38.6392 123.112 38.1863ZM128.181 35.8925C128.766 35.2971 129.059 34.5124 129.059 33.5408C129.059 32.5692 128.766 31.7846 128.181 31.1881C127.596 30.5926 126.855 30.2944 125.959 30.2944C125.062 30.2944 124.324 30.5926 123.746 31.1881C123.168 31.7846 122.878 32.5681 122.878 33.5408C122.878 34.5135 123.168 35.2971 123.746 35.8925C124.324 36.489 125.062 36.7862 125.959 36.7862C126.855 36.7862 127.596 36.4879 128.181 35.8925Z"
            fill="black"
          />
          <path
            d="M139.881 28.2332V30.547C139.673 30.508 139.485 30.4891 139.315 30.4891C138.366 30.4891 137.625 30.7651 137.092 31.3149C136.559 31.8658 136.293 32.6594 136.293 33.6966V38.7316H133.856V28.3512H136.177V29.867C136.879 28.7785 138.114 28.2343 139.882 28.2343L139.881 28.2332Z"
            fill="black"
          />
          <path
            d="M151.601 34.3177H143.43C143.573 35.0823 143.947 35.6877 144.551 36.1351C145.156 36.5825 145.906 36.8062 146.803 36.8062C147.947 36.8062 148.889 36.43 149.631 35.6788L150.937 37.1757C150.469 37.7333 149.877 38.154 149.163 38.4389C148.447 38.7238 147.642 38.8663 146.745 38.8663C145.601 38.8663 144.594 38.6392 143.723 38.1863C142.852 37.7333 142.179 37.1011 141.704 36.2909C141.23 35.4807 140.993 34.5636 140.993 33.5397C140.993 32.5158 141.224 31.6188 141.685 30.8085C142.146 29.9983 142.787 29.3673 143.606 28.9132C144.425 28.4602 145.347 28.2332 146.374 28.2332C147.401 28.2332 148.294 28.4569 149.095 28.9043C149.894 29.3517 150.518 29.9805 150.967 30.7896C151.416 31.5998 151.64 32.5358 151.64 33.5987C151.64 33.7668 151.627 34.0071 151.601 34.3177ZM144.396 30.8675C143.857 31.3272 143.528 31.9404 143.411 32.705H149.319C149.215 31.9538 148.896 31.3439 148.364 30.8775C147.83 30.4112 147.168 30.1775 146.374 30.1775C145.58 30.1775 144.934 30.4079 144.395 30.8675H144.396Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1859_102">
            <rect width="168.076" height="50.9551" rx="9" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default AppStore
