import { Box, Chip } from '@mui/material'
import {
  CheckIcon,
  FlexRow,
  MissingStateIcon,
  MonitorStateIcon,
  QuarantineStateIcon,
  RetiredStateIcon
} from '@papertrail/styleguide'
import React from 'react'

type Props = {
  item
}

const NextCheckAt = (props: Props) => {
  const { item } = props

  const getIcon = (state) => {
    if (state.profile === 'good') {
      return (
        <Box color={'white'} display="flex">
          <CheckIcon />
        </Box>
      )
    } else if (state.profile === 'monitor') {
      return (
        <Box color={'white'} display="flex">
          <MonitorStateIcon />
        </Box>
      )
    } else if (state.profile === 'quarantine') {
      return (
        <Box color={'white'} display="flex">
          <QuarantineStateIcon />
        </Box>
      )
    } else if (state.profile === 'missing') {
      return (
        <Box color={'white'} display="flex">
          <MissingStateIcon />
        </Box>
      )
    } else if (state.profile === 'retired') {
      return (
        <Box color={'white'} display="flex">
          <RetiredStateIcon />
        </Box>
      )
    }
  }

  return (
    <Box paddingTop={'4px'} paddingBottom={'4px'}>
      <FlexRow justifyContent={'space-between'}>
        <Box display="flex" alignItems={'center'} sx={{ color: 'white' }}>
          <Chip
            label={item?.state?.name}
            icon={getIcon(item.state)}
            sx={{ backgroundColor: `rgb(${item?.state?.colour})` || 'inherit', color: 'white', fontSize: '12px' }}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems={'center'}>
          Next Check Date
          <br></br>
          <span style={{ color: !item.is_overdue ? '#0B996A' : '#eb3745' }}>
            {' '}
            {new Date(item?.next_check_at).toLocaleDateString('en-uk', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })}
          </span>
        </Box>
      </FlexRow>
    </Box>
  )
}

export default NextCheckAt
